<template>
  <div class="new">
    <div class="banner_container contactus_banner border_text_box">
      <div class="border_text">News Center</div>
      <h2 class="border_text_title">新闻中心</h2>
    </div>
    <div class="bread_crumbs container_width">
      <span>您的位置：首页 > 新闻中心 > </span>
      <span class="current">集团新闻</span>
    </div>
    <div class="new_container">
        <div class="new_box">
            <h1>关爱特殊人群 贤牛志愿者陪伴残障人员走进海昌海洋公园</h1>
            <div class="time">发布时间：2022-04-14 信息来源： 浏览次数：2391</div>
            <div class="content_line"></div>
            <img src="../assets/imgs/new/text_image_1.webp" alt="">
            <p>5月17日上午，雨后的成都海昌极地海洋公园凉风拂面、空气清新怡人。充满童趣和惊喜的海洋公园当天迎来了一批特殊的观众——成都市谐福残疾人关爱中心的百余名院民，他们在泰合志愿者的陪同下，用自己的方式感受着对生命的敬畏和大自然的热爱。</p>
            <p> 一大早，这群特殊的院民就异常兴奋，早早坐上了成都泰合健康科技集团的交通车，因为与海洋动物亲密接触，是他们多年的愿望。为此，成都泰合健康科技集团的员工克服自身上下班的困难，主动“让出”了交通车，以保障院民当天出行。</p>
            <p>今年4月，当泰合集团获知成都市有一群因为5.12特大地震致残以及先天残疾智障的人群之后，主动与照顾他们的成都市谐福残疾人关爱中心取得联系，表达关爱之情。通过与谐福残疾人关爱中心负责人的深入交流，得知院民有一个参观海洋公园的愿望，泰合集团立即做出回应，表示全程赞助并陪同这群特殊的人亲密接触海洋动物。为了帮助院民做足出行前的身体和体能准备，泰合集团旗下四川洲际胃肠肛门病医院的医护人员专门前往关爱中心为他们和长期陪护的关爱中心志愿者进行义诊和健康咨询。</p>
            <p>“相行相伴，走进海洋公园——520联合关爱行动”活动如期而至。成都海昌极地海洋公园除了对不同等级的残障人士和陪护人员实行票价减免、特惠而外，同时与泰合志愿者一起专门针对此次活动精心规划、设计了参观动线，以保障这群特殊的参观者拥有更好的游览体验。</p>
            <p>在鲸豚表演场，“白鸟先生”用哽咽的话语表达着他的感受：“感谢生命、感恩自然，感谢有这样的机会，让我们和正常人一样体会生命的惊喜和人生的多彩。”这个依靠自强自立毕业于医科大学的先天残疾青年，已出版了他的个人诗集，并在今年“5.12十周年”感恩庆典活动中被授予“自强之星”；而一路上忙着拍照被称为关爱中心“最强互联网工具”的大女孩“丫丫”在她陪护大哥哥的帮助下，留下了很多与海洋动物的合影，她说她要将这些照片发给远在湖南工作的妈妈，让妈妈也能分享她的快乐和幸福；身患视力障碍的小女孩“雪球”是关爱中心年龄最小的院民，今年7岁，她的“视界”充满了与“鲨鱼”的互动，时而欢呼，时而拍手，她用自己的感知解读着心中无限美好的“海洋世界”</p>
            <p>泰合集团多年以来一直热心公益事业，在改善民生、捐资助学、精准扶贫、关注特殊人群等项活动中做出了积极努力。如今“相行相伴关爱特殊人群”已成为集团每年既定的主题公益活动，从成都、南充、广安、泸州，先后为众多需要帮助的人献出爱心，彰显一个社会责任企业应有的价值。</p>
            <img src="../assets/imgs/new/text_image_2.webp" alt="">
            <p>5月17日上午，雨后的成都海昌极地海洋公园凉风拂面、空气清新怡人。充满童趣和惊喜的海洋公园当天迎来了一批特殊的观众——成都市谐福残疾人关爱中心的百余名院民，他们在泰合志愿者的陪同下，用自己的方式感受着对生命的敬畏和大自然的热爱。</p>
            <p> 一大早，这群特殊的院民就异常兴奋，早早坐上了成都泰合健康科技集团的交通车，因为与海洋动物亲密接触，是他们多年的愿望。为此，成都泰合健康科技集团的员工克服自身上下班的困难，主动“让出”了交通车，以保障院民当天出行。</p>
            <p>今年4月，当泰合集团获知成都市有一群因为5.12特大地震致残以及先天残疾智障的人群之后，主动与照顾他们的成都市谐福残疾人关爱中心取得联系，表达关爱之情。通过与谐福残疾人关爱中心负责人的深入交流，得知院民有一个参观海洋公园的愿望，泰合集团立即做出回应，表示全程赞助并陪同这群特殊的人亲密接触海洋动物。为了帮助院民做足出行前的身体和体能准备，泰合集团旗下四川洲际胃肠肛门病医院的医护人员专门前往关爱中心为他们和长期陪护的关爱中心志愿者进行义诊和健康咨询。</p>
            <p>“相行相伴，走进海洋公园——520联合关爱行动”活动如期而至。成都海昌极地海洋公园除了对不同等级的残障人士和陪护人员实行票价减免、特惠而外，同时与泰合志愿者一起专门针对此次活动精心规划、设计了参观动线，以保障这群特殊的参观者拥有更好的游览体验。</p>
            <p>在鲸豚表演场，“白鸟先生”用哽咽的话语表达着他的感受：“感谢生命、感恩自然，感谢有这样的机会，让我们和正常人一样体会生命的惊喜和人生的多彩。”这个依靠自强自立毕业于医科大学的先天残疾青年，已出版了他的个人诗集，并在今年“5.12十周年”感恩庆典活动中被授予“自强之星”；而一路上忙着拍照被称为关爱中心“最强互联网工具”的大女孩“丫丫”在她陪护大哥哥的帮助下，留下了很多与海洋动物的合影，她说她要将这些照片发给远在湖南工作的妈妈，让妈妈也能分享她的快乐和幸福；身患视力障碍的小女孩“雪球”是关爱中心年龄最小的院民，今年7岁，她的“视界”充满了与“鲨鱼”的互动，时而欢呼，时而拍手，她用自己的感知解读着心中无限美好的“海洋世界”</p>
            <p>泰合集团多年以来一直热心公益事业，在改善民生、捐资助学、精准扶贫、关注特殊人群等项活动中做出了积极努力。如今“相行相伴关爱特殊人群”已成为集团每年既定的主题公益活动，从成都、南充、广安、泸州，先后为众多需要帮助的人献出爱心，彰显一个社会责任企业应有的价值。</p>
        </div>
        <div class="switch_article_box container_width">
            <a class="previous" href="#">
                <div>上一篇</div>
                <div>致关心支持贤牛集团的各界朋友的公开信</div>
            </a>
            <a class="next" href="#">
                <div>下一篇</div>
                <div>致315：交付虽然迟到，品质不会缺席</div>
            </a>
        </div>
        <div class="recommended_news_container">
            <div class="recommended_news_box container_width border_text_box">
                <h2 class="border_text">Recommended News</h2>
                <h3 class="border_text_title">推荐新闻</h3>
                <ul class="more_news">
                    <li>
                        <img src="../assets/imgs/new/more_news_1.webp" alt="">
                        <div class="time">2022年5月26</div>
                        <h2 class="title">能量有限 服务无限丨一封来自客户的感谢信</h2>
                        <p>5月17日上午，雨后的成都海昌极地海洋公园凉风拂面、空气清新怡人。充满童趣和惊喜的海洋公园当天迎来了一批特殊的观众—成都市谐福残疾人关爱中心的百余名院民，他们在泰合志愿者的陪同下，用自己的方式感受着对生命的敬畏和大自然的热爱。</p>
                    </li>
                    <li>
                        <img src="../assets/imgs/new/more_news_2.webp" alt="">
                        <div class="time">2022年5月26</div>
                        <h2 class="title">能量有限 服务无限丨一封来自客户的感谢信</h2>
                        <p>5月17日上午，雨后的成都海昌极地海洋公园凉风拂面、空气清新怡人。充满童趣和惊喜的海洋公园当天迎来了一批特殊的观众—成都市谐福残疾人关爱中心的百余名院民，他们在泰合志愿者的陪同下，用自己的方式感受着对生命的敬畏和大自然的热爱。</p>
                    </li>
                    <li>
                        <img src="../assets/imgs/new/more_news_3.webp" alt="">
                        <div class="time">2022年5月26</div>
                        <h2 class="title">能量有限 服务无限丨一封来自客户的感谢信</h2>
                        <p>5月17日上午，雨后的成都海昌极地海洋公园凉风拂面、空气清新怡人。充满童趣和惊喜的海洋公园当天迎来了一批特殊的观众—成都市谐福残疾人关爱中心的百余名院民，他们在泰合志愿者的陪同下，用自己的方式感受着对生命的敬畏和大自然的热爱。</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import '../assets/scss/animation.scss'
import '../assets/scss/communal.scss'
export default {
  name: "new",
}
</script>

<style lang="scss">
@import '../assets/scss/layout.scss';
.new {
    .contactus_banner {
        background: url('../assets/imgs/new/banner.webp') 50% 50%;
        background-size: cover;
        .border_text_title {
          color: #fff
        }
    }
    .new_container {
        .new_box {
            width: 50%;
            margin: 0 auto 0;
            h1 {
                font-size: 40px;
                font-weight: bold;
                text-align: center;
                color: #161616;
                line-height: 60px;
            }
            .time {
                font-size: 13px;
                text-align: center;
                color: #464949;
                line-height: 20px;
                margin-bottom: 40px;
            }
            img {
                width: 100%;
                margin-bottom: 40px;
            }
            p {
                text-indent: 2em;
                font-size: 13px;
                text-align: justify;
                color: #464949;
                line-height: 20px;
                margin-bottom: 40px;
            }
        }
        .switch_article_box {
            @include flexrl();
            font-size: 15px;
            font-weight: bold;
            color: #161616;
            line-height: 20px;
            .next,
            .previous {
                background: #f7f7f7;
                border-radius: 12px 12px 0px 0px;
                padding: 30px 60px;
                position: relative;
                width: calc(50% - 160px);
                div:first-child {
                    margin-bottom: 15px;
                    position: relative;
                }
                div:last-child {
                    font-size: 24px;
                    color: #161616;
                    line-height: 36px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-all;
                }
            }
            .next::before,
            .previous::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: -3px;
                left: 0;
                background: linear-gradient(91deg,#73e0a9, #5b68df 100%);
                border-radius: 50px;
            }
            .previous {
                div:first-child::before {
                    content: '';
                    width: 0px;
                    height: 0px;
                    position: absolute;
                    border-top: solid 15px #000;
                    border-right: solid 15px transparent;
                    left: -20px;
                    transform: rotate(315deg);
                }
            }
            .next {
                text-align: right;
                div:first-child::after {
                    content: '';
                    width: 0px;
                    height: 0px;
                    position: absolute;
                    border-top: solid 15px #000;
                    border-right: solid 15px transparent;
                    right: -20px;
                    transform: rotate(135deg);
                }
            }
        }
        .recommended_news_container {
            margin-top: 140px;
            .recommended_news_box {
                .more_news {
                    @include flexrl();
                    li {
                        width: calc(33% - 30px);
                        font-size: 13px;
                        color: #464949;
                        line-height: 20px;
                        text-align: justify;
                        img {
                            width: 100%;
                        }
                        .time {
                            text-align: right;
                            margin-bottom: 15px;
                        }
                        .title {
                            font-size: 24px;
                            font-weight: bold;
                            color: #161616;
                            line-height: 36px;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1160px){
    .new {
        .new_container {
            .new_box {
                width: 80%;
            }
            .switch_article_box {
                .next,
                .previous {
                    div:last-child {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 820px){}
@media screen and (max-width: 768px){
    .new {
        .new_container {
            .new_box {
                h1 {
                    font-size: 25px;
                    line-height: 40px;
                }
            }
            .switch_article_box {
                flex-direction: column;
                .previous,
                .next {
                    font-size: 18px;
                    width: 60%;
                }
                .previous {
                    margin-bottom: 20px;
                }
                .next {
                    text-align: left;
                    div:first-child::after {
                        left: -30px;
                    }
                }
            }
            .recommended_news_container {
                .recommended_news_box {
                    .more_news {
                        flex-direction: column;
                        li {
                            width: 80%;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}
</style>